import React from 'react';
import PropTypes from 'prop-types';
import ReactWaypoint from 'react-waypoint';

class Waypoint extends React.Component {
  constructor() {
    super();

    this.state = {
      hasEntered: false,
      hasLeft: false,
    };

    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
  }

  onEnter() {
    if (this.props.once) {
      if (!this.state.hasEntered) {
        this.props.onEnter();
        this.setState({ hasEntered: true });
      }
    } else {
      this.props.onEnter();
    }
  }

  onLeave() {
    if (this.props.once) {
      if (!this.state.hasLeft) {
        this.props.onLeave();
        this.setState({ hasLeft: true });
      }
    } else {
      this.props.onLeave();
    }
  }

  render() {
    return (
      <ReactWaypoint onEnter={this.onEnter} onLeave={this.onLeave}>
        {this.props.children}
      </ReactWaypoint>
    );
  }
}

Waypoint.propTypes = {
  children: PropTypes.array,
  once: PropTypes.bool,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
};

Waypoint.defaultProps = {
  children: null,
  once: false,
  onEnter: () => {},
  onLeave: () => {},
};

export default Waypoint;
