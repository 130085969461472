import {
  InstagramPhotoContentCard,
  InstagramVideoContentCard,
  RotoPlayerNewsContentCard,
  RotoPlayerStatsContentCard,
  SourcedArticleCard,
  TwitterGalleryContentCard,
  TwitterPhotoContentCard,
  TwitterTextContentCard,
  TwitterVideoContentCard,
  TwitterVideoStreamContentCard,
  YouTubeContentCard,
} from 'sports-react';

import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from '~/components/Waypoint/Waypoint';
import Article from '~/containers/ArticleContainer/ArticleContainer';
import ContentCardTypes from '~/constants/ContentCardTypes';
import normalizePlayerStats from '~/util/normalize-player-stats';
import moment from 'moment';
import logEvent from '~/util/analytics';
import includes from 'lodash/includes';
import get from 'lodash/get';
import find from 'lodash/find';
import truncate from 'lodash/truncate';
import { connect } from 'react-redux';

const truncateText = text =>
  truncate(text, {
    length: 140,
    separator: /,? +/,
  });

const getPlayer = contentCard => get(contentCard, 'data.player', '');
const getStatsRecord = contentCard => get(contentCard, 'data.stats_record', '');

const getHeadshotUrl = player =>
  get(player, 'headshots.w192xh192', '/static/images/default_headshot.png');

const videoUrl = (videos, isMobile) => {
  if (!videos) return '';

  const sdVideo = find(videos, video => includes(video.resolution, 640));
  const hdVideo = find(videos, video => includes(video.resolution, 1280)); // If this doesn't exist, sdVideo will likely be the next highest available.

  if (hdVideo && !isMobile) {
    return hdVideo.url;
  }
  if (sdVideo) {
    return sdVideo.url;
  }

  return videos[0].url;
};

class ContentCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleCardEnter = this.handleCardEnter.bind(this);
  }

  handleSourcedArticleClick() {
    logEvent('card_click', {
      content_card_type: this.props.contentCard.type,
      destination: this.props.contentCard.data.url,
      article_id: this.props.contentCard.id,
      featured_article: this.props.contentCard.currentCard,
      sourced_article: true,
    });

    const newWindow = window.open() || window;
    newWindow.opener = null; /* Prevent vulnerability */
    newWindow.location = this.props.contentCard.data.url;
  }

  handleCardEnter() {
    logEvent('card_view', {
      content_card_id: this.props.contentCard.id,
      featured_article: this.props.contentCard.currentCard,
    });
  }

  renderCard() {
    const { contentCard, currentCard, isLarge, styleOverrides, showTag } = this.props;
    const player = getPlayer(contentCard);
    const statsRecord = getStatsRecord(contentCard);
    switch (contentCard.type) {
      case ContentCardTypes.INSTAGRAM_PHOTO:
        return (
          <InstagramPhotoContentCard
            isLarge={isLarge}
            authorHandle={contentCard.data.author_handle}
            imageURL={get(contentCard.data, 'images.standard_resolution.url', '')}
            mediaPageUri={contentCard.data.media_page_uri}
            publishedAt={contentCard.data.published_at}
            text={truncateText(contentCard.data.text)}
            shareUrl={contentCard.permalink}
            caption={contentCard.caption}
            modalEnabled
          />
        );
      case ContentCardTypes.INSTAGRAM_VIDEO:
        return (
          <InstagramVideoContentCard
            isLarge={isLarge}
            authorHandle={contentCard.data.author_handle}
            mediaPageUri={contentCard.data.media_page_uri}
            posterURL={get(contentCard.data, 'images.standard_resolution.url', '')}
            publishedAt={contentCard.data.published_at}
            text={truncateText(contentCard.data.text)}
            videoURL={get(contentCard.data, 'videos.standard_resolution.url', '')}
            shareUrl={contentCard.permalink}
            trackEvent={logEvent}
            caption={contentCard.caption}
            modalEnabled
          />
        );
      case ContentCardTypes.PLAYER_UPDATE:
        return (
          <RotoPlayerStatsContentCard
            isLarge={isLarge}
            alignment={statsRecord.alignment}
            awayTeam={get(contentCard.data, 'away_team.abbreviation', '')}
            gameDate={moment(statsRecord.updated_at).format('MMM DD')}
            headshotUrl={getHeadshotUrl(player)}
            homeTeam={get(contentCard.data, 'home_team.abbreviation', '')}
            name={player.full_name}
            publishedAt={statsRecord.updated_at}
            stats={normalizePlayerStats(player, statsRecord)}
            shareUrl={contentCard.permalink}
          />
        );
      case ContentCardTypes.THE_SCORE_ARTICLE:
        return (
          <Article
            isLarge={isLarge}
            contentId={contentCard.id}
            article={contentCard.data}
            currentCard={currentCard}
            styleOverrides={styleOverrides ? styleOverrides.articleStyles : null}
            showTag={showTag}
          />
        );
      case ContentCardTypes.SOURCED_ARTICLE:
        return (
          <div
            style={{ cursor: 'pointer' }}
            role="button"
            tabIndex="0"
            onClick={() => {
              this.handleSourcedArticleClick();
            }}
          >
            <SourcedArticleCard
              isLarge={isLarge}
              imageURL={contentCard.data.image_url}
              publishedAt={contentCard.data.published_at}
              siteName={contentCard.data.site_name}
              title={contentCard.data.title}
              shareUrl={contentCard.permalink}
            />
          </div>
        );
      case ContentCardTypes.ROTO_NEWS:
        return (
          <RotoPlayerNewsContentCard
            isLarge={isLarge}
            headshotUrl={getHeadshotUrl(player)}
            name={get(player, 'first_initial_and_last_name', '')}
            publishedAt={contentCard.data.published_at}
            title={contentCard.data.title}
            shareUrl={contentCard.permalink}
          />
        );
      case ContentCardTypes.TWITTER_GALLERY_CARD:
        if (contentCard.data.gallery) {
          return (
            <TwitterGalleryContentCard
              isLarge={isLarge}
              authorHandle={contentCard.data.author_handle}
              publishedAt={contentCard.data.published_at}
              text={truncateText(contentCard.data.text)}
              url={contentCard.data.url}
              images={contentCard.data.gallery.map(image => get(image, 'images.small.url', ''))}
              shareUrl={contentCard.permalink}
              profileImageUrl={contentCard.data.profile_image_url}
              caption={contentCard.caption}
            />
          );
        }

        return <div />;

      case ContentCardTypes.TWITTER_PHOTO:
        return (
          <TwitterPhotoContentCard
            isLarge={isLarge}
            authorHandle={contentCard.data.author_handle}
            authorName={contentCard.data.author_name}
            imageURL={get(contentCard.data, 'images.small.url', '')}
            profileImageUrl={contentCard.data.profile_image_url}
            profileUrl={contentCard.data.attribution_url}
            publishedAt={contentCard.data.published_at}
            text={truncateText(contentCard.data.text)}
            url={contentCard.data.url}
            verified={contentCard.data.author_verified}
            shareUrl={contentCard.permalink}
            caption={contentCard.caption}
            modalEnabled
          />
        );
      case ContentCardTypes.TWITTER_TEXT:
        return (
          <TwitterTextContentCard
            isLarge={isLarge}
            authorHandle={contentCard.data.author_handle}
            authorName={contentCard.data.author_name}
            profileImageUrl={contentCard.data.profile_image_url}
            profileUrl={contentCard.data.attribution_url}
            publishedAt={contentCard.data.published_at}
            text={truncateText(contentCard.data.text)}
            url={contentCard.data.url}
            verified={contentCard.data.author_verified}
            shareUrl={contentCard.permalink}
            caption={contentCard.caption}
          />
        );
      case ContentCardTypes.TWITTER_VIDEO:
        return (
          <TwitterVideoContentCard
            isLarge={isLarge}
            authorHandle={contentCard.data.author_handle}
            authorName={contentCard.data.author_name}
            posterURL={get(contentCard.data, 'thumbnails.small.url', '')}
            profileImageUrl={contentCard.data.profile_image_url}
            profileUrl={contentCard.data.attribution_url}
            publishedAt={contentCard.data.published_at}
            text={truncateText(contentCard.data.text)}
            url={contentCard.data.url}
            verified={contentCard.data.author_verified}
            videoURL={videoUrl(contentCard.data.videos, this.props.isMobile)}
            shareUrl={contentCard.permalink}
            trackEvent={logEvent}
            caption={contentCard.caption}
            viewOnExternalSite
          />
        );
      case ContentCardTypes.TWITTER_ANIMATED_GIF:
      case ContentCardTypes.TWITTER_VIDEO_STREAM:
        return (
          <TwitterVideoStreamContentCard
            isLarge={isLarge}
            authorHandle={contentCard.data.author_handle}
            authorName={contentCard.data.author_name}
            profileImageUrl={contentCard.data.profile_image_url}
            profileUrl={contentCard.data.attribution_url}
            publishedAt={contentCard.data.published_at}
            text={truncateText(contentCard.data.text)}
            url={contentCard.data.url}
            verified={contentCard.data.author_verified}
            videoID={contentCard.data.id}
            shareUrl={contentCard.permalink}
            caption={contentCard.caption}
            modalEnabled
          />
        );
      case ContentCardTypes.YOU_TUBE:
        return (
          <YouTubeContentCard
            isLarge={isLarge}
            channelTitle={contentCard.data.channel_title}
            publishedAt={contentCard.data.published_at}
            title={contentCard.data.title}
            url={contentCard.data.url}
            videoID={contentCard.data.id}
            shareUrl={contentCard.permalink}
            caption={contentCard.caption}
            modalEnabled
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    return (
      <div className="cap-height">
        {this.renderCard()}
        <Waypoint onEnter={this.handleCardEnter} once />
      </div>
    );
  }
}

ContentCard.propTypes = {
  isMobile: PropTypes.bool,
  contentCard: PropTypes.object,
  currentCard: PropTypes.bool,
  isLarge: PropTypes.bool,
  styleOverrides: PropTypes.object,
  showTag: PropTypes.bool,
};

ContentCard.defaultProps = {
  isMobile: true,
  contentCard: null,
  currentCard: null,
  isLarge: false,
  styleOverrides: null,
  showTag: false,
};

const mapStateToProps = state => ({
  isMobile: state.abTest.data.isMobile,
});

export default connect(mapStateToProps)(ContentCard);
